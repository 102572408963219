import './index.scss';
import Swiper from 'swiper/js/swiper.min.js';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';

//banner鍥惧垏鎹r
let indexBannerSwiperTime = 5000
var indexBannerSwiper = new Swiper('.index-banner-slide .swiper-container', {
  loop: true,
  effect: 'fade',
  autoplay: {
    delay: indexBannerSwiperTime,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {
    el: '.index-banner-slide .swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<div class="' + className + '"><svg><rect></rect><rect></rect></svg></div>';
    },
  }
});

//banner 涓殑鏂伴椈鍒囨崲
var indexBannerNewsSwiper = new Swiper('.index-banner-news-slide .swiper-container', {
  direction: 'vertical',
  loop: true,
  speed: 500,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
  }
});

//棣栭〉浜у搧鏁堟灉
!(function () {
  $('.section2 .section2-cont-item').eq(0).addClass('active')
})()
$('.section2 .section2-cont-item').hover(function () {
  $(this).addClass('active').siblings().removeClass('active')
})

//棣栭〉鏂伴椈swiper
var indexNewSwiper = new Swiper('.section4 .section4-slide .swiper-container', {
  slidesPerView: 1,
  spaceBetween: 10,
  pagination: {
    el: '.section4 .swiper-pagination',
    type: 'fraction',
  },
  navigation: {
    nextEl: '.section4 .section4-slide-control .swiper-button-next',
    prevEl: '.section4 .section4-slide-control .swiper-button-prev',
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  }
});


//椤甸潰婊氬姩鐩戝惉
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //婊氬姩楂樺害
  var viewH = $(window).height(); //鍙楂樺害
  var contentH = $(document).height(); //鍐呭楂樺害
  var section1ScrollTop = $('.section1').offset().top - viewH / 3;
  if (scroH > section1ScrollTop) {
    $('.section1 .animate-circle').addClass('active')
  }
  if (scroH < 100) {
    $('.section1 .animate-circle').removeClass('active')
  }


  if(window.screen.width > 1024){
    $('.section1 .logo-fixed-img').css('bottom', -scroH / 14 + 'px');
    $('.section2 .logo-fixed-img').css('top', scroH / 14 + 'px');
  } else if (window.screen.width <= 1024 && window.screen.width >= 768){
    $('.section1 .logo-fixed-img').css('bottom', -scroH / 15 + 'px');
    $('.section2 .logo-fixed-img').css('top', scroH / 15 + 'px');
  } else if (window.screen.width < 768){
    $('.section1 .logo-fixed-img').css('bottom', -scroH / 20 + 'px');
    $('.section2 .logo-fixed-img').css('top', scroH / 20 + 'px');
  }

});
